import React from 'react';
import moment from 'moment';
import { FeedValues } from 'types/feed';
import { FeedForm } from './feed_form';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_FEED } from 'graphql/mutations/feed';
import { logError } from 'helpers/errors/bug-report';
import { toast } from 'react-toastify';
import { RouteConstants } from 'components/routes/route-constants';
import { useHistory } from 'react-router';
import { camelizeKeys } from 'humps';
import posthog from 'posthog-js';

const FeedAddPage = () => {
  const history = useHistory();

  posthog.capture('FeedAddPage');

  const [addFeed, { loading: isSubmitting }] = useMutation(CREATE_FEED, {
    onCompleted: () => {
      history.push(RouteConstants.feeds);
      toast.success('Feed created successfully');
    },
    onError: (err) => {
      logError(err, 'CREATE_FEED', FeedAddPage.displayName);
      toast.error('Server error occurred');
    },
  });

  const feedValues: FeedValues = {
    title: {
      en: undefined,
      es: undefined,
    },
    description: {
      en: undefined,
      es: undefined,
    },
    link_title: {
      en: 'Learn more...',
      es: 'Saber Más...',
    },
    link_url: {
      en: undefined,
      es: undefined,
    },
    date_start: moment().toDate(),
    channel: 'BOTH',
    image_input: { data: '', file_name: '', mime_type: 'PNG' },
    video_url: '',
    publisher_id: undefined,
    active: true,
  };

  return (
    <FeedForm
      pageTitle='Feed - Add'
      isSubmitting={isSubmitting}
      submitHandler={async (values) => {
        await addFeed({ variables: { input: camelizeKeys(values) } });
      }}
      initialValues={feedValues}
    />
  );
};

FeedAddPage.displayName = 'FeedAddPage';

export { FeedAddPage };
