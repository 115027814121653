import { gql } from 'apollo-boost';

export const BUSINESS_DETAIL_GQL = gql`
  query Business($businessId: ID!) {
    business(id: $businessId) {
      id
      active
      name
      street_1
      street_2
      city
      state
      zip
      phone_number
      allow_dancer_reviews
      organization {
        id
      }
      required_documents
      square_footage
      number_of_stages
      special_requirements_link
      url
      notes
      dancers_are_free_agents
      audition_info
      min_dancers_for_free_agent_search
      max_consecutive_shift_count
      free_agent_search_enabled
      dancer_count
      profile_options {
        id
      }
      hours_of_operation {
        all_day
        open
        close
        closed
        day
        id
      }
      first_image {
        id
        thumb
        medium
      }
      images {
        id
        thumb
        medium
        position
      }
    }
  }
`;
