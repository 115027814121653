export enum DANCER_PAGES {
  SEARCH = 'SEARCH',
  BLOCKED = 'BLOCKED',
  SUSPENDED = 'SUSPENDED',
  ADMIN_SEARCH = 'ADMIN_SEARCH',
}

export enum VERIFICATION_STAGES {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
export enum ACCEPT_REJECT_ACTIONS {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  UNVERIFY = 'UNVERIFY',
}
export enum INVITE_CODE_STATUSES {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export const PHOTO_VERIFICATION_STAGES = {
  ACCEPTED: 'true',
  PENDING: 'null',
  REJECTED: 'false',
};

export enum PHOTO_VERIFICATION_REJECTION_REASONS {
  LOW_QUALITY = 'LOW_QUALITY',
  OFFENSIVE = 'OFFENSIVE',
  MULTIPLE_PEOPLE = 'MULTIPLE_PEOPLE',

  PROFESSIONAL_GUIDELINES = 'PROFESSIONAL_GUIDELINES',
}

export enum LOCALES {
  EN = 'en',
  ES = 'es',
}

export enum UI_MODALS {
  BUG = 'BUG',
  ADD_TO_ORG = 'ADD_TO_ORG',
  ADD_TO_GROUP = 'ADD_TO_GROUP',
  MANAGE_DANCER_GROUPS = 'MANAGE_DANCER_GROUPS',
  BLAST_NOTIFY = 'BLAST_NOTIFY',
  CREATE_GROUP = 'CREATE_GROUP',
  COURSE_FORM = 'COURSE_FORM',
  COURSE_CATEGORY = 'COURSE_CATEGORY',
  DANCER = 'DANCER',
  EMAIL_INVITES = 'EMAIL_INVITES',
  EXISTING_MODAL = 'EXISTING_MODAL',
  INVITE_DANCER = 'INVITE_DANCER',
  INVITE_CODE = 'INVITE_CODE',
  PRE_APPROVED = 'PRE_APPROVED',
  SUSPEND_DANCER = 'SUSPEND_DANCER',
  VERIFICATIONS = 'VERIFICATIONS',
  VIDEO_PLAYER = 'VIDEO_PLAYER',
  PREVIOUS_BOOKINGS = 'PREVIOUS_BOOKINGS',
  PHOTO_VERIFICATIONS = 'PHOTO_VERIFICATIONS',
  CONFIRMATION = 'CONFIRMATION',
  SOCIAL_POST = 'SOCIAL_POST',
  IMAGE_CROP = 'IMAGE_CROP',
  GENERIC = 'GENERIC',
  UNVERIFY = 'UNVERIFY',
}

export enum CAL_VIEWS {
  DAY = 'timeGridDay',
  WEEK = 'timeGridWeek',
  MONTH = 'dayGridMonth',
}

export enum PERMISSIONS {
  EDIT_CLUB = 'edit_club',
  SEND_INVITES = 'send_invites',
  MANAGE_GROUPS = 'manage_groups',
  MANAGE_REQUESTS = 'manage_requests',
  MANAGE_BOOKINGS = 'manage_bookings',
  MANAGE_CONVERSATIONS = 'manage_conversations',
  BLOCK_DANCERS = 'block_dancers',
  CHECK_IN_DANCERS = 'check_in_dancers',
  MANAGE_EMPLOYEES = 'manage_employees',
  SEARCH_DANCERS = 'search_dancers',
  MANAGE_REG_CODES = 'view_registration_codes', // although permission name is 'view_registration_codes' it has create/update priviledges.
}

export enum EMPLOYEE_ROLES {
  ADMIN = 'admin',
  EXECUTIVE = 'executive',
  REGIONAL_MANAGER = 'regional_manager',
  GENERAL_MANAGER = 'general_manager',
  MANAGER = 'manager',
  BOOKING_AGENT = 'booking_agent',
}

export enum BOOKING_STATUS {
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  INVITED = 'INVITED',
  ACCEPTED = 'ACCEPTED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  STANDBY = 'STANDBY',
  FAILED_TO_ATTEND = 'FAILED_TO_ATTEND',
}

export enum ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum GETSTREAM_COMMENT_REPORT_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum GETSTREAM_COMMENT_REPORT_REASON_TYPE {
  inappropriate = 'INAPPROPRIATE',
  offensive = 'OFFENSIVE',
  spam = 'SPAM',
}

export enum GETSTREAM_POST_STATUS {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum GETSTREAM_ADMIN_POST_STATUS {
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  PAUSED = 'PAUSED',
  CANCELLED = 'CANCELLED',
}
