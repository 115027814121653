import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ApolloError } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import camelCase from 'lodash/camelCase';

import { SHIFT_APPLICATION_ACCEPT_GQL } from 'graphql/mutations/shift-application-accept';
import { SHIFT_APPLICATION_PENDING_GQL } from 'graphql/mutations/shift-application-pending';
import { SHIFT_APPLICATION_REJECT_GQL } from 'graphql/mutations/shift-application-reject';
import { SHIFT_STANDBY_GQL } from 'graphql/mutations/shift-application-standby';
import { BOOKING_CHECK_IN_GQL } from 'graphql/mutations/shift-application-check-in';
import { BOOKING_CANCEL_CHECK_IN_GQL } from 'graphql/mutations/shift-application-check-in-undo';
import { BOOKING_CHECK_OUT_GQL } from 'graphql/mutations/shift-application-check-out';
import { BOOKING_CANCEL_CHECK_OUT_GQL } from 'graphql/mutations/shift-application-check-out-undo';
import { CANCEL_BOOKING_INVITE_GQL } from 'graphql/mutations/shift-application-cancel-invite';
import { UNDO_RATING_BATCH_GQL } from 'graphql/mutations/shift-application-rating-undo';

import { ActionMapType, ActionResult, MutationResult } from './types';
import { SHIFT_APPLICATION_FAILED_TO_ATTEND_GQL } from 'graphql/mutations/shift-application-failed-to-attend';

const useActions = (onActionSuccess: (ids: string[], action: string) => void) => {
  const { t } = useTranslation();

  const handleSuccesses = (successes: ActionResult[], action: string) => {
    const successIds = successes.map(({ shift_application: { id } }) => id);
    onActionSuccess(successIds, action);
    let key = action;
    if (action === 'pending' && successes[0].shift_application.is_invite) {
      key = 'invited';
    }
    toast.success(t(`manageBookings.${key}.success`));
  };

  const handleFailures = (failures: ActionResult[]) => {
    failures.forEach((failure) => {
      handleOnError(failure);
    });
  };

  const handleOnError = (error: ApolloError | ActionResult) => {
    let toastMessage;
    switch (error.message) {
      case 'CONFLICTING_SHIFTS':
        toastMessage = 'manageBookings.accept.failure.CONFLICTING_SHIFTS';
        break;
      case 'SHIFT_HAS_ENDED':
        toastMessage = 'manageBookings.checkIn.failure.SHIFT_HAS_ENDED';
        break;
      case 'SHIFT_APPLICATION_NOT_ACCEPTED':
        toastMessage = 'manageBookings.common.failure.SHIT_APPLICATION_NOT_ACCEPTED';
        break;
      default:
        toastMessage = 'manageBookings.shift_update_failed.default';
        break;
    }
    toast.error(t(toastMessage));
  };

  const handleOnCompleted = (data: MutationResult) => {
    const field = Object.keys(data)[0];
    const action = camelCase(field.split('shift_application_')[1]);
    const { successes, failures } = data[field];
    if (failures.length) {
      handleFailures(failures);
    }
    if (successes.length) {
      handleSuccesses(successes, action);
    }
  };

  const options = {
    onError: handleOnError,
    onCompleted: handleOnCompleted,
  };

  const [ACCEPT, { loading: accepting }] = useMutation(SHIFT_APPLICATION_ACCEPT_GQL, options);
  const [PENDING, { loading: pending }] = useMutation(SHIFT_APPLICATION_PENDING_GQL, options);
  const [REJECT, { loading: rejecting }] = useMutation(SHIFT_APPLICATION_REJECT_GQL, options);
  const [STANDBY, { loading: standbying }] = useMutation(SHIFT_STANDBY_GQL, options);
  const [CHECK_IN, { loading: checkingIn }] = useMutation(BOOKING_CHECK_IN_GQL, options);
  const [CHECK_IN_UNDO, { loading: undoingCheckIn }] = useMutation(BOOKING_CANCEL_CHECK_IN_GQL, options);
  const [CHECK_OUT, { loading: checkingOut }] = useMutation(BOOKING_CHECK_OUT_GQL, options);
  const [CHECK_OUT_UNDO, { loading: undoingCheckOut }] = useMutation(BOOKING_CANCEL_CHECK_OUT_GQL, options);
  const [INVITE_UNDO, { loading: undoingInvite }] = useMutation(CANCEL_BOOKING_INVITE_GQL, options);
  const [FAILED_TO_ATTEND, { loading: failingToAttend }] = useMutation(SHIFT_APPLICATION_FAILED_TO_ATTEND_GQL, options);
  const [RATE_UNDO, { loading: undoingRate }] = useMutation(UNDO_RATING_BATCH_GQL, options);

  const actions: ActionMapType = {
    ACCEPT,
    PENDING,
    REJECT,
    STANDBY,
    CHECK_IN,
    CHECK_IN_UNDO,
    CHECK_OUT,
    CHECK_OUT_UNDO,
    INVITE_UNDO,
    FAILED_TO_ATTEND,
    RATE_UNDO,
  };

  const mutate = async (action: string, idArray: string[]) => {
    await (actions as ActionMapType)[action]({ variables: { idArray } });
  };

  return {
    mutate,
    isLoading:
      accepting ||
      checkingIn ||
      pending ||
      rejecting ||
      standbying ||
      checkingIn ||
      undoingCheckIn ||
      checkingOut ||
      undoingCheckOut ||
      undoingInvite ||
      failingToAttend ||
      undoingRate,
  };
};

export default useActions;
