import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { FaCommentAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { useModal } from '../../../../../global-hooks';
import { UI_MODALS } from '../../../../../../helpers/enums';
import S from '../../../../../../sass/buttons/modern-btn.module.scss';

const BtnGroupBlast = ({ groupId }) => {
  const { t } = useTranslation();
  const { initModal } = useModal();

  const handleBlast = useCallback(() => initModal(UI_MODALS.BLAST_NOTIFY, { id: groupId }), [initModal, groupId]);

  return (
    <button onClick={handleBlast} className={S.modernBtn}>
      <FaCommentAlt /> {t('groupsPage.blast')}
    </button>
  );
};

BtnGroupBlast.displayName = 'BtnGroupBlast';

BtnGroupBlast.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export { BtnGroupBlast };
