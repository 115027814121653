import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './row-booking-buttons.module.scss';
import { useModalBookingCtx } from '../../modal-booking-context';
import { setMetaFields } from '../../modal-booking-actions';

const RowBookingButtons = ({ onClose, bookingId, disableSubmit }) => {
  const { dispatch } = useModalBookingCtx();
  const { t } = useTranslation();
  const handleCancel = useCallback(() => {
    if (bookingId) {
      dispatch(
        setMetaFields({
          isEditable: false,
          confirm: false,
          isMany: false,
        })
      );
    } else onClose();
  }, [dispatch, bookingId, onClose]);

  return (
    <div className={S.bookingModalActionArea}>
      <button style={{ gridArea: 'cancel' }} className={S.invertedBtnRed} type='button' onClick={handleCancel}>
        {t('common.cancel')}
      </button>
      <button style={{ gridArea: 'submit' }} className={S.invertedBtnGreen} disabled={disableSubmit}>
        {t('common.save')}
      </button>
    </div>
  );
};

RowBookingButtons.displayName = 'BookingActions';

RowBookingButtons.propTypes = {
  bookingId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

RowBookingButtons.defaultProps = {
  bookingId: null,
};

export { RowBookingButtons };
