import { gql } from 'apollo-boost';
import { SHIFT_APPLICATION_FRAGMENT } from '../fragments/shift-application-fragment';

export const SHIFT_STANDBY_GQL = gql`
    ${SHIFT_APPLICATION_FRAGMENT}
    mutation ShiftAppStandby($idArray: [ID!]!){
        shift_application_standby(
            input:{ ids: $idArray }
        ) {
            failures { id message }
            successes {
                message
                shift_application {
                    ...shiftApplicationFragment
                }
            }
        }
    }
`;
