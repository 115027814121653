import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_FEED_LIST, GET_FEED_PUBLISHERS } from '../../../graphql/queries/feed';
import { logError } from '../../../helpers/errors/bug-report';
import { RouteConstants } from '../../routes/route-constants';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { FeedItem, FeedPublisher } from 'types/feed';
import { LoadingDisco, LoadingDiscoWithContainer } from 'components/common/loading/loading-disco';
import { CogIcon } from '@heroicons/react/24/solid';
import posthog from 'posthog-js';

const FeedPage = () => {
  const [pageInfo, setPageInfo] = useState({ endCursor: '', hasNextPage: false });
  const [searchTitle, setSearchTitle] = useState<string>();
  const [searchChannel, setSearchChannel] = useState<string>();
  const [searchPublisher, setSearchPublisher] = useState<string>();
  const [viewLanguage, setViewLanguage] = useState<'en' | 'es'>('en');

  posthog.capture('FeedPage');

  const { data: publishersData, loading: publishersLoading } = useQuery<{ feed_publishers: FeedPublisher[] }>(
    GET_FEED_PUBLISHERS,
    {
      fetchPolicy: 'network-only',
      onError: (err) => logError(err, 'GET_FEED_PUBLISHERS', FeedPage.displayName),
    }
  );

  const { refetch, data, loading, called, fetchMore } = useQuery<{
    feed_schedules: {
      totalCount: number;
      pageInfo: { startCursor: string; endCursor: string; hasNextPage: boolean; hasPreviousPage: boolean };
      nodes: FeedItem[];
    };
  }>(GET_FEED_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      first: 20,
      title: searchTitle,
      channel: searchChannel,
      publisher_id: searchPublisher,
    },
    onCompleted: (data) => setPageInfo(data.feed_schedules.pageInfo),
    onError: (err) => logError(err, 'GET_FEED_LIST', FeedPage.displayName),
  });

  const handleSearch = async () => {
    await refetch({
      variables: {
        first: 20,
        title: searchTitle,
        channel: searchChannel,
        publisher_id: searchPublisher,
      },
    });
  };

  useEffect(() => {
    if (data) {
      handleSearch();
    }
  }, [searchChannel, searchPublisher]);

  useEffect(() => {
    if (searchTitle && searchTitle.length >= 3 && data) {
      handleSearch();
    }
  }, [searchTitle]);

  const getMore = async () =>
    await fetchMore({
      variables: {
        first: 20,
        title: searchTitle,
        channel: searchChannel,
        publisher_id: searchPublisher,
        cursor: pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        setPageInfo(fetchMoreResult.feed_schedules.pageInfo);

        return {
          feed_schedules: {
            ...fetchMoreResult.feed_schedules,
            nodes: [...previousResult.feed_schedules.nodes, ...fetchMoreResult.feed_schedules.nodes],
          },
        };
      },
    });

  return (
    <div className='container mx-auto mt-4'>
      <div className='px-4 w-full flex flex-row justify-between'>
        <div className='inline text-gray-300 text-2xl font-bold'>
          Feed - ({data?.feed_schedules?.totalCount} items){' '}
        </div>
        <Link
          to={RouteConstants.feedAdd}
          className='inline px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        >
          + New Feed Item
        </Link>
      </div>
      <div className='flex flex-row space-between'>
        <div className='w-1/2 p-4'>
          <label htmlFor='title' className='block text-sm font-medium text-gray-700'>
            Search
          </label>
          <input
            type='text'
            id='title'
            name='title'
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            className='h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block text-gray-800 w-full sm:text-sm border-gray-300 px-4 rounded-full'
            placeholder='Search by title'
          />
        </div>
        <div className='p-4'>
          <label htmlFor='channel' className='block text-sm font-medium text-gray-700'>
            Channel
          </label>
          <select
            name='channel'
            id='channel'
            value={searchChannel}
            onChange={(e) => setSearchChannel(e.target.value)}
            className='mt-1 block w-full pl-3 pr-10 py-2 text-base text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
          >
            <option>All channels</option>
            <option value='dancer'>Dancer</option>
            <option value='club'>Club</option>
            <option value='both'>Both</option>
          </select>
        </div>
        <div className='p-4'>
          <label htmlFor='publisher' className='block text-sm font-medium text-gray-700'>
            Publisher
          </label>
          {publishersLoading ? (
            <div className='p-1 h-12 w-32 text-gray-500'>
              <CogIcon className='animate-spin h-8 ml-4' />
            </div>
          ) : (
            <select
              name='publisher'
              id='publisher'
              value={searchPublisher}
              onChange={(e) => setSearchPublisher(e.target.value)}
              className='mt-1 block w-full pl-3 pr-10 py-2 text-base text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
            >
              <option>All publishers</option>
              {publishersData &&
                publishersData.feed_publishers.map((publisher) => {
                  return (
                    <option key={publisher.id} value={publisher.id}>
                      {publisher.name}
                    </option>
                  );
                })}
            </select>
          )}
        </div>
        <div className='p-4'>
          <label className='block text-sm font-medium text-gray-700'>Language</label>
          <fieldset className='mt-4'>
            <div className='space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10'>
              <div className='flex items-center'>
                <input
                  id='en'
                  name='language'
                  type='radio'
                  checked={viewLanguage === 'en'}
                  onChange={() => setViewLanguage('en')}
                  className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300'
                />
                <label htmlFor='en' className='ml-3 block text-sm font-medium text-gray-300'>
                  {' '}
                  English{' '}
                </label>
              </div>
              <div className='flex items-center'>
                <input
                  id='es'
                  name='language'
                  type='radio'
                  checked={viewLanguage === 'es'}
                  onChange={() => setViewLanguage('es')}
                  className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300'
                />
                <label htmlFor='sms' className='ml-3 block text-sm font-medium text-gray-300'>
                  {' '}
                  Spanish{' '}
                </label>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      {loading ? (
        <LoadingDiscoWithContainer />
      ) : (
        data &&
        data.feed_schedules.nodes.map((item, idx) => (
          <div
            key={item.id}
            className={classNames(['flex flex-row items-start p-2 mb-2', idx % 2 === 0 && 'bg-gray-800'])}
          >
            <div className='min-w-fit basis-64 p-2'>
              <img src={item.image.medium} alt={item.title.en} className='object-contain h-60 w-60 bg-gray-300' />
            </div>
            <div className='basis-4/5 self-start p-2'>
              <div className='text-gray-300 text-sm'>Publisher: {item.publisher.name}</div>
              <div className='text-gray-300 text-xl font-bold break-normal'>{item.title[viewLanguage]}</div>
              <div className='text-gray-300 text-md truncate' title={item.description[viewLanguage]}>
                {item.description[viewLanguage]}
              </div>
              <div className='text-gray-300 text-sm'>URL: {item.link_url[viewLanguage]}</div>
              <div className='text-gray-300 text-sm'>{item.link_title[viewLanguage]}</div>
              <div className='text-gray-300 text-sm'>Start Date: {item.date_start}</div>
              <div className='text-gray-300 text-sm'>Channel: {item.channel}</div>
            </div>
            <div className='min-w-fit h-full basis-32 p-2 flex flex-col'>
              <Link
                to={generatePath(RouteConstants.feedEdit, { feedId: item.id })}
                className='inline w-32 mb-4 px-4 py-2 border text-sm font-medium rounded-md shadow-sm text-white border-indigo-600 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Edit
              </Link>
              <Link
                to={generatePath(RouteConstants.feedClone, { feedId: item.id })}
                className='inline w-32 mb-4 px-4 py-2 border text-sm font-medium rounded-md shadow-sm text-white border-green-600 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
              >
                Clone
              </Link>
            </div>
          </div>
        ))
      )}
      <div className='flex mx-auto justify-center my-8'>
        {!called && loading ? (
          <LoadingDisco />
        ) : (
          !loading &&
          pageInfo.hasNextPage && (
            <button
              onClick={getMore}
              className='finline px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Load more...
            </button>
          )
        )}
      </div>
    </div>
  );
};

FeedPage.displayName = 'FeedPage';

export { FeedPage };
