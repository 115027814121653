import { gql } from 'apollo-boost';

export const CREATE_BUSINESS = gql`
  mutation (
    $name: String!
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $postal: String!
    $url: String
    $phone: String
    $schedule: [HoursOfOperationInputObject!]!
    $orgId: ID!
    $isActive: Boolean
    $allowReviews: Boolean
    $stageCount: Int
    $sqFootage: Int
    $maxBookings: Int
    $profileOptions: [ID!]
    $requiredDocs: JSON
    $hasFreeAgents: Boolean
    $hasRegLink: Boolean
    $regLink: String
    $auditionInfo: String
    $notes: String
    $images: [ImageInputObject!]
    $minDancersForFreeAgentSearch: Int
  ) {
    business_create(
      input: {
        createParams: {
          name: $name
          street1: $street1
          street2: $street2
          city: $city
          state: $state
          zip: $postal
          phoneNumber: $phone
          url: $url
          hoursAttributes: $schedule
          organizationId: $orgId
          active: $isActive
          allowDancerReviews: $allowReviews
          numberOfStages: $stageCount
          squareFootage: $sqFootage
          maxConsecutiveShiftCount: $maxBookings
          profileOptionIds: $profileOptions
          requiredDocuments: $requiredDocs
          auditionInfo: $auditionInfo
          notes: $notes
          dancersAreFreeAgents: $hasFreeAgents
          specialRequirements: $hasRegLink
          specialRequirementsLink: $regLink
          imageInputObjects: $images
          minDancersForFreeAgentSearch: $minDancersForFreeAgentSearch
        }
      }
    ) {
      business {
        id
        name
        street_1
        street_2
        city
        state
        zip
        url
        phone_number
        hours_of_operation {
          id
          all_day
          closed
          close
          open
        }

        organization {
          id
        }
        active
        allow_dancer_reviews
        number_of_stages
        square_footage
        max_consecutive_shift_count
        min_dancers_for_free_agent_search
        free_agent_search_enabled
        dancer_count
        profile_options {
          id
          name
          trans_key
        }
        required_documents

        dancers_are_free_agents
        special_requirements
        special_requirements_link

        audition_info
        notes
      }
    }
  }
`;
