import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import classNames from 'classnames';
import { logError } from 'helpers/errors/bug-report';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { camelizeKeys } from 'humps';
import * as Yup from 'yup';
import { ButtonSpinner } from 'components/common/button_spinner';
import { GET_FEED_PUBLISHERS } from 'graphql/queries/feed';
import { CREATE_FEED_PUBLISHER, UPDATE_FEED_PUBLISHER } from 'graphql/mutations/feed';
import { ALL_BUSINESS_GQL } from 'graphql/queries/business-get-all';
import { Business } from 'types/business';
import { LoadingDiscoWithContainer } from 'components/common/loading/loading-disco';
import { FeedPublisher, FeedPublisherCreate, FeedPublisherUpdate } from 'types/feed';
import { getBase64Image } from 'helpers/image_to_base64';
import ModalFeedPhoto from 'components/modals/modal_feed_photo';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  publisher: FeedPublisher | undefined;
  title: string;
}

const ModalPublisher: React.FC<Props> = (props) => {
  const { isOpen, closeModal, publisher, title } = props;
  const [imageData, setImageData] = React.useState<string>('');
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);

  const { data, loading } = useQuery<{ businesses: Business[] }>(ALL_BUSINESS_GQL, {
    onError: (err: Error) => {
      logError(err, 'ALL_BUSINESS_GQL', ModalPublisher.displayName);
      toast.error('Server error occurred');
    },
  });

  const [addPublisher, { loading: isAddSubmitting }] = useMutation(CREATE_FEED_PUBLISHER, {
    onCompleted: () => {
      toast.success('Publisher added successfully');
      closeModal();
    },
    refetchQueries: [{ query: GET_FEED_PUBLISHERS }],
    onError: (err: Error) => {
      logError(err, 'CREATE_FEED_PUBLISHER_LINK', ModalPublisher.displayName);
      toast.error('Server error occurred');
    },
  });

  const [updatePublisher, { loading: isUpdateSubmitting }] = useMutation(UPDATE_FEED_PUBLISHER, {
    onCompleted: () => {
      toast.success('Publisher updated successfully');
      closeModal();
    },
    refetchQueries: [{ query: GET_FEED_PUBLISHERS }],
    onError: (err: Error) => {
      logError(err, 'UPDATE_FEED_PUBLISHER', ModalPublisher.displayName);
      toast.error('Server error occurred');
    },
  });

  if (publisher?.image) {
    getBase64Image(publisher.image.large, setImageData);
  }

  if (publisher && publisher.image && imageData === '') return <LoadingDiscoWithContainer />;

  const initialValues: FeedPublisherCreate | FeedPublisherUpdate = {
    id: publisher?.id || undefined,
    name: publisher ? publisher.name : '',
    notes: publisher ? publisher.notes : '',
    business_id: publisher && publisher.business ? publisher.business.id : undefined,
    image_input: {
      data: publisher && publisher.image ? imageData : '',
      file_name: publisher && publisher.image ? publisher.image.data_file_name : '',
      mime_type: 'PNG',
    },
  };

  const isSubmitting = isAddSubmitting || isUpdateSubmitting;

  if (loading || !data) {
    return <LoadingDiscoWithContainer />;
  }

  const linkValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    notes: Yup.string(),
    image_input: Yup.object().shape({
      data: Yup.string().required('Image is required'),
      file_name: Yup.string().required('Image is required'),
      mime_type: Yup.string().required('Image is required'),
    }),
  });

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed z-10 inset-0 overflow-y-auto'>
          <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm sm:w-full sm:p-6'>
                <div>
                  <div className='mt-3 sm:mt-5'>
                    <Dialog.Title as='h3' className='text-lg leading-6 text-center font-medium text-gray-300'>
                      {title}
                    </Dialog.Title>
                    <div className='mt-2'>
                      <div className='mt-5 sm:mt-6'>
                        <Formik
                          validationSchema={linkValidationSchema}
                          initialValues={initialValues}
                          onSubmit={(values) => {
                            const camelizedValues = camelizeKeys(values);
                            if (values.id !== undefined) {
                              updatePublisher({ variables: { input: camelizedValues } });
                            } else {
                              addPublisher({ variables: { input: camelizedValues } });
                            }
                          }}
                        >
                          {({ values, touched, errors, isValid, setFieldValue }) => {
                            return (
                              <Form>
                                <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3 mb-2'>
                                  <div className='md:grid md:grid-cols-3 md:gap-6'>
                                    <div className='sm:col-span-3'>
                                      <label htmlFor='photo' className='block text-sm font-medium text-gray-500'>
                                        Photo
                                      </label>
                                      <div className='mt-1 flex items-center'>
                                        <span className='h-96 w-96 mr-4'>
                                          {values.image_input.data ? (
                                            <img
                                              src={values.image_input.data}
                                              alt='photo'
                                              className='h-96 w-96 object-contain rounded-full'
                                            />
                                          ) : (
                                            <img
                                              src='https://icon-library.com/images/image-placeholder-icon/image-placeholder-icon-7.jpg'
                                              alt='photo'
                                              className='h-96 w-96 object-contain rounded-full'
                                            />
                                          )}
                                        </span>
                                      </div>
                                      <div className='w-96 bg-white py-2 px-3 border border-gray-300 rounded-none rounded-b-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                                        <ModalFeedPhoto
                                          isOpen={openPhotoModal}
                                          circularCrop={true}
                                          closeModal={() => setOpenPhotoModal(false)}
                                          imageData={values.image_input}
                                          setImageData={(imageData) => setFieldValue('image_input', imageData)}
                                        />
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setOpenPhotoModal(true);
                                          }}
                                          className='w-full block self-center space-x-2 px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        >
                                          {values.image_input.data ? 'Change & Crop photo' : 'Upload and Crop photo'}
                                        </button>
                                        <ErrorMessage
                                          name='image_input.data'
                                          className='text-red-500 text-sm'
                                          component='div'
                                        />
                                      </div>
                                    </div>

                                    <div className='sm:col-span-3'>
                                      <label htmlFor='name' className='block text-sm font-medium text-gray-500'>
                                        Name
                                      </label>
                                      <div className='mt-1 flex rounded-md shadow-sm'>
                                        <Field
                                          type='text'
                                          name='name'
                                          id='name'
                                          className={classNames(
                                            'h-9 px-4 py-2 flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                            { ['border-2  border-red-500']: touched.name && errors.name }
                                          )}
                                        />
                                      </div>
                                      <ErrorMessage name='name' className='text-red-500 text-sm' component='div' />
                                    </div>
                                  </div>
                                </div>

                                <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3 mb-2'>
                                  <div className='md:grid md:grid-cols-3 md:gap-6'>
                                    <div className='sm:col-span-3 mb-2'>
                                      <label htmlFor='notes' className='block text-sm font-medium text-gray-500'>
                                        Notes
                                      </label>
                                      <div className='mt-1 flex rounded-md shadow-sm'>
                                        <Field
                                          as='textarea'
                                          rows={4}
                                          name='notes'
                                          id='notes'
                                          className={classNames(
                                            'px-4 py-2 mt-1 block w-full pl-3 pr-10 py-2 text-base text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md',
                                            { ['border-2  border-red-500']: touched.notes && errors.notes }
                                          )}
                                        />
                                      </div>
                                      <ErrorMessage name='notes' className='text-red-500 text-sm' component='div' />
                                    </div>
                                  </div>
                                </div>

                                <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3 mb-2'>
                                  <div className='md:grid md:grid-cols-3 md:gap-6'>
                                    <div className='sm:col-span-3 mb-2'>
                                      <label htmlFor='business_id' className='block text-sm font-medium text-gray-500'>
                                        Associated Business
                                      </label>
                                      <div className='mt-1 flex rounded-md shadow-sm'>
                                        <Field
                                          as='select'
                                          name='business_id'
                                          id='business_id'
                                          className={classNames(
                                            'flex-1 py-2 px-2 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                            { ['border-2  border-red-500']: touched.business_id && errors.business_id }
                                          )}
                                        >
                                          <option value=''>-- None --</option>
                                          {data.businesses.map((business) => (
                                            <option key={business.id} value={business.id}>
                                              {business.name}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>
                                      <ErrorMessage
                                        name='business_id'
                                        className='text-red-500 text-sm'
                                        component='div'
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className='flex justify-between mt-4'>
                                  <button
                                    type='button'
                                    className='w-40 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm'
                                    onClick={() => {
                                      closeModal();
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type='submit'
                                    disabled={!isValid || isSubmitting}
                                    className={classNames([
                                      isValid ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-600',
                                      'w-40 ml-3 inline-flex justify-center py-2 px-4 rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm',
                                    ])}
                                  >
                                    {isSubmitting && <ButtonSpinner />} Done
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ModalPublisher.displayName = 'ModalPublisher';

ModalPublisher.propTypes = {};

export { ModalPublisher };
