import { gql } from 'apollo-boost';

export const UPDATE_SHIFT = gql`
    mutation ShiftUpdate(
        $shiftId:ID!
        $startTime: DateTime!
        $endTime: DateTime!
        $slots: Int!
        $preapprovedIds: [ID!]!
        $autoCheckoutInterval: Int
        $hardcapped: Boolean!
        $recurring: Boolean
        $separateSeries: Boolean
    ){
        shift_update(input:{
            recurring: $recurring
            detachFromSiblingRecurrences: $separateSeries
            shiftUpdateParams:{
                id: $shiftId
                startTime: $startTime
                endTime: $endTime
                preapprovedGroupIds: $preapprovedIds
                slots: $slots
                autoCheckoutInterval: $autoCheckoutInterval
                hardcapped: $hardcapped
            },
        }){
            clientMutationId
        }
    }
`;
