import { gql } from 'apollo-boost';

export const SHIFT_PROMO_CREATE = gql`
    mutation PromoCreate(
        $businessId:ID!
        $startTime: DateTime!
        $endTime: DateTime!
        $slots: Int!
        $title: String!
        $description: String!
        $notifyIds: [ID!]!
        $preapprovedIds: [ID!]!
        $autoCheckoutInterval: Int
        $hardcapped: Boolean!
    ){
        shift_promo_create(input:{
            notifications: $notifyIds,
            promoParams:{
                businessId: $businessId,
                startTime: $startTime,
                endTime: $endTime,
                preapprovedGroupIds: $preapprovedIds
                slots: $slots,
                title:$title,
                description:$description
                autoCheckoutInterval: $autoCheckoutInterval
                hardcapped: $hardcapped
            },
        }){
            promo {
                id
                start_time
                end_time
                title
                description
                slots
                business { id }
                preapproved_group_ids
                auto_checkout_interval
            }
        }
    }
`;
