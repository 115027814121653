import React from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import { FeedForm } from './feed_form';
import { FeedItem, FeedValues } from '../../../types/feed';
import { GET_FEED_ITEM } from 'graphql/queries/feed';
import { logError } from 'helpers/errors/bug-report';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { RouteConstants } from 'components/routes/route-constants';
import { UPDATE_FEED } from 'graphql/mutations/feed';
import { toast } from 'react-toastify';
import { camelizeKeys } from 'humps';
import { LoadingDiscoWithContainer } from 'components/common/loading/loading-disco';
import { ImageInput } from 'types';
import { getBase64Image } from 'helpers/image_to_base64';
import posthog from 'posthog-js';

const FeedEditPage = () => {
  const { feedId } = useParams<{ feedId: string }>();
  const [imageData, setImageData] = React.useState<string>('');
  const history = useHistory();

  posthog.capture('FeedEditPage', { feedId: feedId });

  const { data, loading, error } = useQuery<{ feed_item: FeedItem }>(GET_FEED_ITEM, {
    fetchPolicy: 'network-only',
    variables: {
      feedId,
    },
    onError: (err: Error) => logError(err, 'GET_FEED_ITEM', FeedEditPage.displayName),
  });

  const [updateFeed, { loading: isSubmitting }] = useMutation(UPDATE_FEED, {
    onCompleted: () => {
      history.push(RouteConstants.feeds);
      toast.success('Feed updated successfully');
    },
    onError: (err) => {
      logError(err, 'UPDATE_FEED', FeedEditPage.displayName);
      toast.error('Server error occurred');
    },
  });

  if (loading || !data) {
    return <LoadingDiscoWithContainer />;
  }

  if (data.feed_item.image) {
    getBase64Image(data.feed_item.image.large, setImageData);
  }

  if (data.feed_item.image && imageData === '') return <LoadingDiscoWithContainer />;

  const imageInput: ImageInput = {
    data: imageData,
    file_name: data.feed_item.image.data_file_name,
    mime_type: 'PNG',
  };

  const feedValues: FeedValues = {
    id: data.feed_item.id,
    title: {
      en: data.feed_item.title.en,
      es: data.feed_item.title.es,
    },
    description: {
      en: data.feed_item.description.en,
      es: data.feed_item.description.es,
    },
    link_title: {
      en: data.feed_item.link_title.en,
      es: data.feed_item.link_title.es,
    },
    link_url: {
      en: data.feed_item.link_url.en,
      es: data.feed_item.link_url.es,
    },
    video_url: data.feed_item.video_url,
    channel: data.feed_item.channel,
    date_start: moment(data.feed_item.date_start).toDate(),
    image_input: imageInput,
    publisher_id: data.feed_item.publisher.id,
    active: data.feed_item.active,
  };

  return (
    <FeedForm
      pageTitle='Feed - Edit'
      isSubmitting={isSubmitting}
      submitHandler={async (values) => await updateFeed({ variables: { input: camelizeKeys(values) } })}
      initialValues={feedValues}
    />
  );
};

FeedEditPage.displayName = 'FeedEditPage';

export { FeedEditPage };
