import React, { useState } from 'react';
import { FormProps } from './types';
import useImageKit from 'helpers/hooks/use-imagekit';
import { GetstreamMediaInput } from 'types/getstream';

const useSubmitForm = (action: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (values: FormProps) => {
    try {
      setIsLoading(true);
      const media: GetstreamMediaInput[] = [];
      const imagekit = useImageKit();

      await Promise.all(
        values.media.map(async (item: any, idx: number) => {
          const order = idx + 1;
          if (item.base64) {
            const {
              fileType,
              url,
              hasSound,
              width,
              height,
              fileId: externalFileId,
            } = await imagekit.upload(item.base64, 'pp_post', '/feed/poleposition', ['feed', 'post', 'poleposition']);

            media.push({
              mediaType: fileType === 'image' ? 'PHOTO' : 'VIDEO',
              order,
              url,
              hasSound,
              width,
              height,
              externalFileId,
            });
          } else
            media.push({
              mediaType: item.media_type,
              order,
              url: item.url,
              hasSound: item.has_sound,
              width: item.width,
              height: item.height,
              externalFileId: item.external_file_id,
            });
        })
      );

      const variables = {
        input: {
          id: values.id,
          publishAt: values.publishAt ? values.publishAt : undefined,
          publishNow: !!!values.publishAt,
          title: values.title,
          text: values.text,
          link: {
            title: values.link_title,
            url: values.link_url,
          },
          media,
        },
      };

      await action({ variables });
    } catch (error) {
      console.log('Error useSubmitForm:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    submit,
    isLoading,
  };
};

export default useSubmitForm;
