import { gql } from 'apollo-boost';

export const GET_FEED_LIST = gql`
  query GetFeedSchedules($first: Int, $cursor: String, $channel: String, $businessId: ID, $title: String) {
    feed_schedules(first: $first, after: $cursor, channel: $channel, publisherId: $businessId, title: $title) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        title {
          en
          es
        }
        description {
          en
          es
        }
        link_title {
          en
          es
        }
        link_url {
          en
          es
        }
        date_start
        channel
        image {
          id
          medium
        }
        video_url
        publisher {
          id
          name
          image {
            id
            thumb
            medium
          }
        }
        active
      }
    }
  }
`;

export const GET_FEED_ITEM = gql`
  query GetFeedItem($feedId: ID!) {
    feed_item(id: $feedId) {
      id
      title {
        en
        es
      }
      description {
        en
        es
      }
      link_title {
        en
        es
      }
      link_url {
        en
        es
      }
      date_start
      channel
      image {
        id
        large
        medium
        data_content_type
        data_file_name
      }
      video_url
      publisher {
        id
        name
      }
      active
    }
  }
`;

export const GET_FEED_PUBLISHERS = gql`
  query FeedPublishers {
    feed_publishers {
      id
      name
      notes
      image {
        id
        medium
        large
        data_content_type
        data_file_name
      }
      business {
        id
      }
    }
  }
`;
