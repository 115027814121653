import React, { ReactEventHandler, useEffect } from 'react';
import { Formik, Form, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FeedValues, FeedPublisher } from '../../../types/feed';
import { useQuery } from '@apollo/react-hooks';
import { logError } from 'helpers/errors/bug-report';
import { GET_FEED_PUBLISHERS } from 'graphql/queries/feed';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import ModalFeedPhoto from '../../modals/modal_feed_photo';
import { LoadingSpinner } from 'components/common/loading/loading-spinner';
import { RouteConstants } from 'components/routes/route-constants';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';
import { TranslateButton } from 'components/common/translate_button';
import { ButtonSpinner } from 'components/common/button_spinner';
import { LoadingDiscoWithContainer } from 'components/common/loading/loading-disco';
import { ModalPublisher } from './modal_publisher';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid';

interface Props {
  pageTitle: string;
  submitHandler: (values: any) => void;
  isSubmitting: boolean;
  initialValues: FeedValues;
}
const FeedForm: React.FC<Props> = (props) => {
  const history = useHistory();
  const { data, loading } = useQuery<{ feed_publishers: FeedPublisher[] }>(GET_FEED_PUBLISHERS, {
    fetchPolicy: 'network-only',
    onError: (err: Error) => logError(err, 'GET_FEED_PUBLISHERS', FeedForm.displayName),
  });

  const { submitHandler, initialValues, pageTitle, isSubmitting } = props;
  const [selectedPublisher, setSelectedPublisher] = React.useState<FeedPublisher>();
  const [openPublisherModal, setOpenPublisherModal] = React.useState(false);
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);

  useEffect(() => {
    if (initialValues.publisher_id && data) {
      setSelectedPublisher(data.feed_publishers.find((p) => p.id === initialValues.publisher_id));
    }
  }, [initialValues.publisher_id, data]);

  if (loading || !data) {
    return <LoadingDiscoWithContainer />;
  }

  const multiLangSchema = (finalValidation: Yup.AnySchema) =>
    Yup.object().shape(
      {
        en: Yup.string()
          .nullable()
          .when('es', {
            is: (es: string) => !isEmpty(es),
            then: finalValidation,
          }),
        es: Yup.string()
          .nullable()
          .when('en', {
            is: (en: string) => !isEmpty(en),
            then: finalValidation,
          }),
      },
      [['es', 'en']]
    );

  const feedSchema = Yup.object().shape({
    title: Yup.object().shape({
      en: Yup.string().required('Title is required'),
      es: Yup.string().required('Title is required'),
    }),
    description: multiLangSchema(Yup.string().nullable().required('Required if other description is filled')),
    link_title: Yup.object().shape({
      en: Yup.string().required('Link Title is required'),
      es: Yup.string().required('Link Title is required'),
    }),
    link_url: multiLangSchema(
      Yup.string().url('Must be a valid URL').nullable().required('Required if other URL is filled')
    ),
    date_start: Yup.date().required('Date is required'),
    channel: Yup.string().oneOf(['DANCER', 'CLUB', 'BOTH']),
    image_input: Yup.object().shape({
      data: Yup.string().required('Image is required'),
      file_name: Yup.string().required('Image is required'),
      mime_type: Yup.string().required('Image is required'),
    }),
    video_url: Yup.string().nullable().url('Must be a valid URL'),
    publisher_id: Yup.string().required('Publisher is required'),
  });

  return (
    <div className='container w-4/5 mx-auto mt-4 mx-8 mb-8'>
      <div className='w-full text-gray-300 text-2xl font-bold'>{pageTitle}</div>
      <Formik
        initialValues={initialValues}
        validationSchema={feedSchema}
        onSubmit={(values) => {
          submitHandler(values);
        }}
      >
        {({ values, errors, touched, setFieldValue, isValid }) => {
          return (
            <Form className='space-y-8 divide-y divide-gray-200'>
              <div className='space-y-8 divide-y divide-gray-200'>
                <div>
                  <div>
                    <p className='mt-1 text-sm text-gray-500'>
                      This information will be displayed publicly so be careful what you share.
                    </p>
                  </div>

                  <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                    <div className='sm:col-span-3'>
                      <label htmlFor='channel' className='block text-sm font-medium text-gray-500'>
                        Publisher
                      </label>
                      <div className='mt-1'>
                        <ModalPublisher
                          isOpen={openPublisherModal}
                          closeModal={() => setOpenPublisherModal(false)}
                          publisher={selectedPublisher}
                          title='Publisher'
                        />
                        {loading ? (
                          <LoadingSpinner />
                        ) : (
                          <div className='flex justify-between content-center'>
                            <Field
                              as='select'
                              id='publisher_id'
                              name='publisher_id'
                              className={classNames(
                                'block w-full pl-3 pr-10 py-2 text-base text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md',
                                { ['border-2  border-red-500']: touched.publisher_id && errors.publisher_id }
                              )}
                              onChange={(e: any) => {
                                e.preventDefault();
                                const publisher = data.feed_publishers.find((p) => p.id === e.target.value);
                                setSelectedPublisher(publisher);
                                setFieldValue('publisher_id', e.target.value);
                              }}
                            >
                              {!values.publisher_id && <option>-- Select Publisher --</option>}
                              {data &&
                                data.feed_publishers.map((pub) => (
                                  <option key={pub.id} value={pub.id}>
                                    {pub.name}
                                  </option>
                                ))}
                            </Field>
                            <button
                              title='Edit selected publisher'
                              type='button'
                              disabled={!selectedPublisher}
                              onClick={() => {
                                setOpenPublisherModal(true);
                              }}
                              className='w-9 ml-2 h-9 p-2 border border-gray-300 rounded-md text-xs text-gray-300 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            >
                              <PencilIcon />
                            </button>
                            <button
                              title='Add new publisher'
                              type='button'
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedPublisher(undefined);
                                setOpenPublisherModal(true);
                              }}
                              className='w-9 ml-2 h-9 px-2 border border-gray-300 rounded-md text-xs text-gray-300 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            >
                              <PlusIcon />
                            </button>
                          </div>
                        )}
                        <ErrorMessage name='publisher_id' className='text-red-500 text-sm' component='div' />
                      </div>
                    </div>

                    <div className='sm:col-span-3'>
                      <label htmlFor='channel' className='block text-sm font-medium text-gray-500'>
                        {' '}
                        Channel{' '}
                      </label>
                      <div className='mt-1'>
                        <Field
                          as='select'
                          id='channel'
                          name='channel'
                          className={classNames(
                            'mt-1 block w-full pl-3 pr-10 py-2 text-base text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md',
                            { ['border-2  border-red-500']: touched.channel && errors.channel }
                          )}
                        >
                          <option value='DANCER'>Dancer</option>
                          <option value='CLUB'>Club</option>
                          <option value='BOTH'>Both</option>
                        </Field>
                        <ErrorMessage name='channel' className='text-red-500 text-sm' component='div' />
                      </div>
                    </div>

                    <div className='sm:col-span-3'>
                      <label htmlFor='photo' className='block text-sm font-medium text-gray-500'>
                        Photo
                      </label>
                      <div className='mt-1 flex items-center'>
                        <span className='h-96 w-96 mr-4'>
                          {values.image_input.data ? (
                            <img src={values.image_input.data} alt='photo' className='h-96 w-96 object-contain' />
                          ) : (
                            <img
                              src='https://icon-library.com/images/image-placeholder-icon/image-placeholder-icon-7.jpg'
                              alt='photo'
                              className='h-96 w-96 object-contain'
                            />
                          )}
                        </span>
                      </div>
                      <div className='w-96 bg-white py-2 px-3 border border-gray-300 rounded-none rounded-b-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                        <ModalFeedPhoto
                          isOpen={openPhotoModal}
                          closeModal={() => setOpenPhotoModal(false)}
                          imageData={values.image_input}
                          setImageData={(imageData) => setFieldValue('image_input', imageData)}
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenPhotoModal(true);
                          }}
                          className='w-full block self-center space-x-2 px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        >
                          {values.image_input.data ? 'Change & Crop photo' : 'Upload and Crop photo'}
                        </button>
                        <ErrorMessage name='image_input.data' className='text-red-500 text-sm' component='div' />
                      </div>
                    </div>

                    <div className='sm:col-span-3'>
                      <label htmlFor='date_start' className='block text-sm font-medium text-gray-500'>
                        {' '}
                        Start Date{' '}
                      </label>
                      <div className='mt-1'>
                        <Field
                          id='date_start'
                          name='date_start'
                          className={classNames({
                            ['border-2  border-red-500']: touched.date_start && errors.date_start,
                          })}
                        >
                          {({ field }: FieldProps) => (
                            <DatePicker
                              selected={values.date_start}
                              onChange={(value) => setFieldValue('date_start', value)}
                              showTimeSelect
                              minDate={new Date()}
                              timeFormat='HH:mm'
                              timeIntervals={60}
                              timeCaption='time'
                              inline
                              dateFormat='yyyy-MM-dd hh:mm'
                            />
                          )}
                        </Field>
                        <ErrorMessage name='date_start' className='text-red-500 text-sm' component='div' />
                      </div>
                    </div>

                    <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-6'>
                      <div className='md:grid md:grid-cols-3 md:gap-6'>
                        <div className='sm:col-span-3'>
                          <h3 className='text-lg font-medium leading-6 text-gray-500'>Title</h3>
                          <p className='mt-1 text-sm text-gray-500'>The title of the feed item.</p>
                        </div>
                        <div className='sm:col-span-4'>
                          <label htmlFor='title.en' className='block text-sm font-medium text-gray-500'>
                            English
                          </label>
                          <div className='mt-1 flex rounded-md shadow-sm'>
                            <Field
                              type='text'
                              name='title.en'
                              id='title.en'
                              className={classNames(
                                'h-9 px-4 flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                { ['border-2  border-red-500']: touched.title?.en && errors.title?.en }
                              )}
                            />
                          </div>
                          <ErrorMessage name='title.en' className='text-red-500 text-sm' component='div' />
                        </div>
                        <div className='sm:col-span-4'>
                          <label htmlFor='title.es' className='block text-sm font-medium text-gray-500'>
                            Spanish
                          </label>
                          <div className='mt-1 flex rounded-md shadow-sm'>
                            <Field
                              type='text'
                              name='title.es'
                              id='title.es'
                              className={classNames(
                                'h-9 px-4 flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300',
                                { ['border-2  border-red-500']: touched.title?.es && errors.title?.es }
                              )}
                            />
                            <TranslateButton
                              stringOriginal={values.title.en}
                              langFrom='en'
                              langTo='es'
                              flatLeft
                              onTranslate={(translatedString) => setFieldValue('title.es', translatedString)}
                            />
                          </div>
                          <ErrorMessage name='title.es' className='text-red-500 text-sm' component='div' />
                        </div>
                      </div>
                    </div>

                    <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-6'>
                      <div className='md:grid md:grid-cols-3 md:gap-6'>
                        <div className='sm:col-span-3'>
                          <h3 className='text-lg font-medium leading-6 text-gray-500'>Description</h3>
                          <p className='mt-1 text-sm text-gray-500'>
                            The following text wil appear below the title on the feed screen.
                          </p>
                        </div>
                        <div className='sm:col-span-6'>
                          <label htmlFor='description.en' className='block text-sm font-medium text-gray-500'>
                            English
                          </label>
                          <div className='mt-1'>
                            <Field
                              as='textarea'
                              id='description.en'
                              name='description.en'
                              rows={5}
                              className={classNames(
                                'px-4 py-2 shadow-sm text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md',
                                { ['border-2  border-red-500']: touched.description?.en && errors.description?.en }
                              )}
                            />
                          </div>
                          <ErrorMessage name='description.en' className='text-red-500 text-sm' component='div' />
                        </div>
                        <div className='sm:col-span-6'>
                          <label htmlFor='description.es' className='block text-sm font-medium text-gray-500'>
                            Spanish
                          </label>
                          <div className='mt-1'>
                            <div className='relative flex items-stretch flex-grow focus-within:z-10'>
                              <Field
                                as='textarea'
                                id='description.es'
                                name='description.es'
                                rows={5}
                                className={classNames(
                                  'px-4 py-2 shadow-sm text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md',
                                  { ['border-2  border-red-500']: touched.description?.es && errors.description?.es }
                                )}
                              />
                            </div>
                            <TranslateButton
                              stringOriginal={values.description.en}
                              langFrom='en'
                              langTo='es'
                              onTranslate={(translatedString) => setFieldValue('description.es', translatedString)}
                            />
                          </div>
                          <ErrorMessage name='description.es' className='text-red-500 text-sm' component='div' />
                        </div>
                      </div>
                    </div>

                    <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3'>
                      <div className='md:grid md:grid-cols-3 md:gap-6'>
                        <div className='sm:col-span-3'>
                          <h3 className='text-lg font-medium leading-6 text-gray-500'>Link Text</h3>
                          <p className='mt-1 text-sm text-gray-500'>Text for the link.</p>
                        </div>
                        <div className='sm:col-span-3'>
                          <label htmlFor='link_title.en' className='block text-sm font-medium text-gray-500'>
                            English
                          </label>
                          <div className='mt-1 flex rounded-md shadow-sm'>
                            <Field
                              type='text'
                              name='link_title.en'
                              id='link_title.en'
                              className={classNames(
                                'h-9 flex-1 px-4 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                { ['border-2  border-red-500']: touched.link_title?.en && errors.link_title?.en }
                              )}
                            />
                          </div>
                          <ErrorMessage name='link_title.en' className='text-red-500 text-sm' component='div' />
                        </div>
                        <div className='sm:col-span-3'>
                          <label htmlFor='link_title.es' className='block text-sm font-medium text-gray-500'>
                            Spanish
                          </label>
                          <div className='mt-1 flex rounded-md shadow-sm'>
                            <div className='relative flex items-stretch flex-grow focus-within:z-10'>
                              <Field
                                type='text'
                                name='link_title.es'
                                id='link_title.es'
                                className={classNames(
                                  'h-9 px-4 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300',
                                  { ['border-2  border-red-500']: touched.link_title?.es && errors.link_title?.es }
                                )}
                              />
                            </div>
                            <TranslateButton
                              stringOriginal={values.link_title.en}
                              langFrom='en'
                              langTo='es'
                              flatLeft
                              onTranslate={(translatedString) => setFieldValue('link_title.es', translatedString)}
                            />
                          </div>
                          <ErrorMessage name='link_title.es' className='text-red-500 text-sm' component='div' />
                        </div>
                      </div>
                    </div>

                    <div className='bg-gray-800 shadow px-4 py-5 sm:rounded-lg sm:p-6 sm:col-span-3'>
                      <div className='md:grid md:grid-cols-3 md:gap-6'>
                        <div className='sm:col-span-3'>
                          <h3 className='text-lg font-medium leading-6 text-gray-500'>Link URL</h3>
                          <p className='mt-1 text-sm text-gray-500'>
                            A window will open to this link if it is included.
                          </p>
                        </div>
                        <div className='sm:col-span-3'>
                          <label htmlFor='link_url.en' className='block text-sm font-medium text-gray-500'>
                            English
                          </label>
                          <div className='mt-1 flex rounded-md shadow-sm'>
                            <Field
                              type='text'
                              name='link_url.en'
                              id='link_url.en'
                              className={classNames(
                                'h-9 px-4 flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                { ['border-2  border-red-500']: touched.link_url?.en && errors.link_url?.en }
                              )}
                            />
                          </div>
                          <ErrorMessage name='link_url.en' className='text-red-500 text-sm' component='div' />
                        </div>
                        <div className='sm:col-span-3'>
                          <label htmlFor='link_url.es' className='block text-sm font-medium text-gray-500'>
                            Spanish
                          </label>
                          <div className='mt-1 flex rounded-md shadow-sm'>
                            <Field
                              type='text'
                              name='link_url.es'
                              id='link_url.es'
                              className={classNames(
                                'h-9 px-4 flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300',
                                { ['border-2  border-red-500']: touched.link_url?.es && errors.link_url?.es }
                              )}
                            />
                          </div>
                          <ErrorMessage name='link_url.es' className='text-red-500 text-sm' component='div' />
                        </div>
                      </div>
                    </div>

                    {/* <div className='sm:col-span-4'>
                    <label htmlFor='video_url' className='block text-sm font-medium text-gray-500'>
                      Video URL (Optional)
                    </label>
                    <div className='mt-1 flex rounded-md shadow-sm'>
                      <Field
                        type='text'
                        name='video_url'
                        id='video_url'
                        className='flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                      />
                    </div>
                  </div> */}

                    <div className='sm:col-span-4'>
                      <label htmlFor='active' className='block text-sm font-medium text-gray-500'>
                        Active
                      </label>
                      <div className='mt-1 flex rounded-md shadow-sm'>
                        <Field
                          name='active'
                          id='active'
                          className='flex-1 text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300'
                        >
                          {() => (
                            <button
                              type='button'
                              onClick={() => setFieldValue('active', !values.active)}
                              className={classNames(
                                values.active ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              )}
                              role='switch'
                              aria-checked='false'
                            >
                              <span className='sr-only'>Is Active</span>
                              <span
                                aria-hidden='true'
                                className={classNames(
                                  values.active ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                )}
                              ></span>
                            </button>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='pt-5'>
                <div className='flex justify-end'>
                  <button
                    type='button'
                    onClick={() => history.push(RouteConstants.feeds)}
                    className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    disabled={!isValid || isSubmitting}
                    className={classNames(
                      { ['bg-indigo-600 hover:bg-indigo-700']: isValid },
                      { ['bg-gray-600']: !isValid },
                      'w-40 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    {isSubmitting && <ButtonSpinner />} Save
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

FeedForm.displayName = 'FeedForm';

export { FeedForm };
