import { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ACTION_TYPES } from './constants';
import { ActionButtonProps } from './types';

import S from '../../../sass/buttons/button.module.scss';

const {
  PENDING,
  STANDBY,
  ACCEPT,
  REJECT,
  INVITE_UNDO,
  CHECK_IN,
  CHECK_OUT,
  CHECK_IN_UNDO,
  CHECK_OUT_UNDO,
  FAILED_TO_ATTEND,
  RATE_UNDO,
} = ACTION_TYPES;

const actionNameMap = {
  PENDING: 'undo',
  STANDBY: STANDBY,
  ACCEPT: ACCEPT,
  REJECT: REJECT,
  INVITE_UNDO: 'cancel',
  FAILED_TO_ATTEND: 'missed',
  CHECK_IN: CHECK_IN,
  CHECK_IN_UNDO: 'undo',
  CHECK_OUT: CHECK_OUT,
  CHECK_OUT_UNDO: 'undo',
  RATE_UNDO: RATE_UNDO,
};

const ActionButton: FC<ActionButtonProps> = ({ action, isMany, onClick, disabled }) => {
  const { t } = useTranslation();

  const className = classNames({
    [S.invertedBtnYellow]: action === PENDING,
    [S.invertedBtnBlue]: [
      STANDBY,
      CHECK_IN,
      CHECK_IN_UNDO,
      CHECK_OUT,
      CHECK_OUT_UNDO,
      FAILED_TO_ATTEND,
      RATE_UNDO,
    ].includes(action),
    [S.invertedBtnGreen]: action === ACCEPT,
    [S.invertedBtnRed]: [REJECT, INVITE_UNDO].includes(action),
    ['opacity-50']: disabled,
  });

  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      {`${t(`common.${actionNameMap[action].toLowerCase()}`)}${
        isMany ? ` ${t('common.selected')}` : action === 'INVITE_UNDO' ? ` ${t('common.invite')}` : ''
      }`.toUpperCase()}
    </button>
  );
};

export default ActionButton;
