import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { CallPutPasswordReset } from 'rest-calls';
import { validatePassword } from 'helpers/validations/login-validations';

import { LoginInputField } from '../login-input-field';

import S from '../pre-login-parts.module.scss';

const ERROR_TEXT = { scope: 'errors' };
const DEFAULT_STATE = { password: '', confirm: '' };

type ResetFormProps = {
  onSuccess: (hasSubmitted: boolean) => void;
};

const ResetForm: React.FC<ResetFormProps> = ({ onSuccess }): React.ReactElement => {
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(false);
  const [values, setValues] = useState(DEFAULT_STATE);
  const [errors, setErrors] = useState(DEFAULT_STATE);

  const handleChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      setValues({ ...values, [name]: value.trim() });
    },
    [values]
  );

  const handleBlur = useCallback(
    (e) => {
      const { value, name } = e.target;
      setValues({ ...values, [name]: value.trim() });
      let errorObj = { ...errors, [name]: validatePassword(value.trim()) };

      if (values.password && values.confirm && values.password !== values.confirm) {
        errorObj = { ...errorObj, confirm: t('errors.password.confirm', ERROR_TEXT) };
      }
      setErrors(errorObj);
    },
    [values, errors]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      let errorObj = {
        password: validatePassword(values.password),
        confirm: validatePassword(values.confirm),
      };

      if (errorObj.confirm === '' && values.password !== values.confirm) {
        errorObj = { ...errorObj, confirm: t('errors.password.confirm', ERROR_TEXT) };
      }

      setErrors(errorObj);
      if (Object.values(errorObj).some((v) => v !== '')) return;

      (async () => {
        try {
          setIsDisabled(true);
          await CallPutPasswordReset({
            password: values.password,
            password_confirmation: values.confirm,
            nonce: new URLSearchParams(window.location.search).get('nonce') || '',
          });

          onSuccess(true);
        } catch (err: any) {
          setIsDisabled(false);
          toast.error(t(err.response.status === 422 ? 'server.badLink' : 'server.500', ERROR_TEXT));
        } // don't use finally due to unMount onSuccess
      })();
    },
    [values, onSuccess]
  );

  return (
    <form className={S.userForm} onSubmit={handleSubmit}>
      <h1 className={S.pageHeading}>{t('reset.heading')}</h1>

      <LoginInputField
        label={t('reset.fields.password')}
        id='password'
        type='password'
        name='password'
        value={values.password}
        isDisabled={isDisabled}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.password}
      />

      <LoginInputField
        label={t('reset.fields.confirm')}
        id='confirm'
        name='confirm'
        type='password'
        value={values.confirm}
        isDisabled={isDisabled}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.confirm}
      />

      <button type='submit' className={S.submitBtn} disabled={isDisabled}>
        {t('reset.submit')}
      </button>

      <Link to={'/'} className={S.activeLink}>
        {t('reset.back')}
      </Link>
    </form>
  );
};

ResetForm.displayName = 'ResetFormPresenter';

ResetForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export { ResetForm };
