import { gql } from 'apollo-boost';
import { SHIFT_APPLICATION_FRAGMENT } from '../fragments/shift-application-fragment';

export const SHIFT_APPLICATION_ACCEPT_GQL = gql`
    ${SHIFT_APPLICATION_FRAGMENT}
    mutation shift_application_accept($idArray: [ID!]!){
        shift_application_accept(input:{
            ids: $idArray
        }){
            failures { id message }
            successes {
                message
                shift_application {
                    ...shiftApplicationFragment
                }
            }
        }
    }
`;
