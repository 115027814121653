export const RouteConstants = {
  blastNotifications: '/admin/blasts',
  groupNotifications: '/admin/announcements',
  courses: '/admin/courses',
  adminConversations: '/admin/conversations',
  adminCodes: '/admin/invites',
  calendar: '/calendar',
  applicationRequests: '/requests/:type/:bookingId?',
  bugs: '/bugs',
  bugDetail: '/bugs/:bugId',
  clubs: '/clubs',
  newClub: '/clubs/new',
  clubInvites: '/clubs/invites',
  conversations: '/conversations',
  dancersSearch: '/entertainers',
  adminDancersSearch: '/admin/entertainers',
  dancersSearchSuspended: '/entertainers/suspended',
  dancerDetails: '/entertainers/:dancerId',
  groupSearch: '/groups',
  groupDetails: '/groups/:groupId',
  employeeSearch: '/employees',
  employeeNew: '/employees/new',
  employeeEdit: '/employees/:employmentId/settings',
  employeeInvites: '/employees/:employeeId/invites',
  inviteRequests: '/invites',
  logOut: '/logout',
  clubSettings: '/settings',
  userForm: '/users/:userId',
  timeout: '/timeout',
  verifications: '/verifications',
  photoVerifications: '/veritifcations-photos',
  dobChangeRequest: '/dob-change-requests',
  statistics: '/stats',
  vipPromos: '/vip',
  vipOfferAdd: '/vip/:sectionId/offer/add',
  vipOfferEdit: '/vip/:sectionId/offer/:offerId',
  feeds: '/feed',
  feedAdd: '/feed/add',
  feedEdit: '/feed/:feedId',
  feedClone: '/feed/clone/:feedId',
  socialPosts: '/social/posts',
  socialComments: '/social/comments',
  socialScheduledPosts: '/social/scheduled-posts',
  socialScheduledPostAdd: '/social/scheduled-posts/add/:postId?',
  socialScheduledPostEdit: '/social/scheduled-posts/edit/:postId',
};

export const RouteCreator = {
  applicationRequests: (bookingId, isAudition) =>
    `/requests/${isAudition ? 'auditions' : 'bookings'}${bookingId ? '/' + bookingId : ''}`,
  dancerDetails: (id) => `/entertainers/${id}`,
  groupDetails: (id) => `/groups/${id}`,
  employeeEdit: (id) => `/employees/${id}/settings`,
  employeeInvites: (id) => `/employees/${id}/invites`,
  userForm: (id) => `/users/${id}`,
};
