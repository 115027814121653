import moment from 'moment';
import * as ClubFormCtx from './club-form-context';

export const shiftImagesLeft = (imageObj, index) =>
  Object.values(imageObj).reduce(
    (acm, cur, i) =>
      i <= index
        ? acm
        : {
            ...acm,
            [i - 1]: cur,
            [i]: { image_attachment: null, preview: null, id: null, isLoading: false },
          },
    imageObj
  );

export const swapImgsLeft = (imgObj, sourceIndex, targetIndex, isLoading) => {
  const IMG_LIST = Object.values(imgObj);
  const SMALLER_INDEX = sourceIndex < targetIndex ? sourceIndex : targetIndex;
  let LARGER_INDEX = sourceIndex > targetIndex ? sourceIndex : targetIndex;
  if (LARGER_INDEX > 6) LARGER_INDEX = 6;

  if (sourceIndex < targetIndex) {
    // shift left
    const SMALLER = imgObj[SMALLER_INDEX];
    return IMG_LIST.reduce((acm, cur, i) => {
      if (i < SMALLER_INDEX || i > LARGER_INDEX) return { ...acm };
      else if (i === SMALLER_INDEX) return { ...acm, [i]: { ...acm[i + 1], isLoading } };
      else if (i === LARGER_INDEX) return { ...acm, [i]: { ...SMALLER, isLoading } };
      else return { ...acm, [i]: { ...imgObj[i + 1], isLoading } };
    }, imgObj);
  } else {
    // shift right
    const LARGER = imgObj[LARGER_INDEX];
    return IMG_LIST.reduce((acm, cur, i) => {
      if (i < SMALLER_INDEX || i > LARGER_INDEX) return { ...acm };
      else if (i === SMALLER_INDEX) return { ...acm, [i]: { ...LARGER, isLoading } };
      else return { ...acm, [i]: { ...imgObj[i - 1], isLoading } };
    }, imgObj);
  }
};

export const setClubFromCache = (dispatch, cacheObj) => {
  dispatch(
    ClubFormCtx.setClubFormFields({
      id: cacheObj.id,
      name: cacheObj.name,
      street1: cacheObj.street_1,
      street2: cacheObj.street_2,
      city: cacheObj.city,
      state: cacheObj.state,
      postal: cacheObj.zip,
      phone: cacheObj.phone_number,
      url: cacheObj.url,
      schedule: cacheObj.hours_of_operation.reduce((acm, { day, open, close, all_day, closed, id }) => {
        let openTime;

        if (all_day) openTime = 'allDay';
        else if (closed) openTime = 'closed';
        else openTime = moment.utc(open).format('hh:mmA');

        return {
          ...acm,
          [day]: {
            id,
            open: openTime,
            close: moment.utc(close).format('hh:mmA'),
          },
        };
      }, {}),

      orgId: cacheObj.organization.id,
      isActive: cacheObj.active,
      allowReviews: cacheObj.allow_dancer_reviews,
      stageCount: String(cacheObj.number_of_stages),
      sqFootage: String(cacheObj.square_footage),
      maxBookings: String(cacheObj.max_consecutive_shift_count) || '0',
      profileOptions: cacheObj.profile_options.map(({ id }) => id) ?? [],
      requiredDocs: cacheObj.required_documents ?? [],
      auditionInfo: cacheObj.audition_info ?? '',
      notes: cacheObj.notes ?? '',
      regLink: cacheObj.special_requirements_link,
      hasFreeAgents: cacheObj.dancers_are_free_agents || false,
      minDancersForFreeAgentSearch: cacheObj.min_dancers_for_free_agent_search,
      dancerCount: cacheObj.dancer_count,
      images: cacheObj.images.reduce(
        (acm, { thumb, position, id }) => {
          return { ...acm, [position]: { image_attachment: null, preview: thumb, id, isLoading: false } };
        },
        {
          0: { image_attachment: null, preview: null, isLoading: false, id: null },
          1: { image_attachment: null, preview: null, isLoading: false, id: null },
          2: { image_attachment: null, preview: null, isLoading: false, id: null },
          3: { image_attachment: null, preview: null, isLoading: false, id: null },
          4: { image_attachment: null, preview: null, isLoading: false, id: null },
          5: { image_attachment: null, preview: null, isLoading: false, id: null },
          6: { image_attachment: null, preview: null, isLoading: false, id: null },
        }
      ),
    })
  );
};
