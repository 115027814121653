import { gql } from 'apollo-boost';

export const BUSINESS_FRAGMENT = gql`
  fragment business on Business {
    id
    name
    street_1
    street_2
    city
    state
    zip
    phone_number
    longitude
    latitude
    timezone
    allow_dancer_reviews
    free_agent_search_enabled
    first_image {
      id
      thumb
      medium
    }
    organization {
      id
      name
    }
    hours_of_operation {
      all_day
      open
      close
      closed
      day
      id
    }
    closed
  }
`;
