import { useApolloClient, useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './booking-form.module.scss';
import { RowBookingButtons } from './row-booking-buttons';
import { RowBookingEvent } from './row-booking-event';
import { RowBookingPromo } from './row-booking-promo';
import { RowBookingTime } from './row-booking-time';
import { BookingCheckBox } from './booking-form-fields/booking-checkbox';
import { BookingDuplicateField } from './booking-form-fields/booking-duplicate-field';
import { BookingGroupsSelect } from './booking-form-fields/booking-groups-select';
import { BookingSelectorField } from './booking-form-fields/booking-selector-field';
import { setBookingError, setBookingField } from '../../modal-booking-actions';
import { useModalBookingCtx } from '../../modal-booking-context';
import * as BookingServices from '../../modal-booking-services';
import { FormNumberField } from '../../../../common/form/form-number-field';
import { FormSelectField } from '../../../../common/form/form-select-field';
import { useBusiness } from '../../../../../graphql/graph-hooks';
import { SHIFT_EVENT_CREATE } from '../../../../../graphql/mutations/event-create';
import { SHIFT_EVENT_UPDATE } from '../../../../../graphql/mutations/event-update';
import { CREATE_SHIFT_GQL } from '../../../../../graphql/mutations/shift-create';
import { UPDATE_SHIFT } from '../../../../../graphql/mutations/shift-update';
import { GET_GROUP_NAME_LIST } from '../../../../../graphql/queries/group-name-list';
import { BOOKING_TYPES } from '../../../../../helpers/constants';
import { logError } from '../../../../../helpers/errors/bug-report';
import { checkForFilterError } from '../../../../../helpers/filtered-words';
import RichToast from 'components/common/rich-toast';
import useSuggestions, { SuggestionListType } from 'helpers/hooks/useSuggestions';
import { SHIFT_PROMO_CREATE } from '../../../../../graphql/mutations/promo-create';
import { SHIFT_PROMO_UPDATE } from '../../../../../graphql/mutations/promo-update';

const BookingForm = ({ bookingId, onClose, onCompleted, setLoading }) => {
  const CLIENT = useApolloClient();
  const { t } = useTranslation();
  const { id, timezone } = useBusiness();
  const { groups } = CLIENT.readQuery({
    query: GET_GROUP_NAME_LIST,
    variables: { businessId: id },
  });
  const { checkText } = useSuggestions(SuggestionListType.MANAGER);

  const BOOKING_TYPE_OPTIONS = [
    { label: t('bookingCalendar.select_booking_type'), value: '' },
    { label: t('bookingCalendar.promo'), value: BOOKING_TYPES.PROMO },
    { label: t('bookingCalendar.audition'), value: BOOKING_TYPES.AUDITION },
    { label: t('bookingCalendar.event'), value: BOOKING_TYPES.EVENT },
    { label: t('bookingCalendar.day_showtime'), value: BOOKING_TYPES.DAY },
    { label: t('bookingCalendar.evening_showtime'), value: BOOKING_TYPES.EVENING },
    { label: t('bookingCalendar.night_showtime'), value: BOOKING_TYPES.NIGHT },
    { label: t('bookingCalendar.late_night_showtime'), value: BOOKING_TYPES.LATE_NIGHT },
  ];

  const GROUP_ID_LIST = useMemo(() => groups.map(({ id }) => id), [groups]);
  const GROUP_OPTIONS = useMemo(() => groups.map(({ id, name }) => ({ value: id, label: name })), [groups]);

  const { state, dispatch } = useModalBookingCtx();

  const IS_CREATE = useMemo(() => !bookingId, [bookingId]);
  const IS_EVENT = useMemo(() => state.form.booking_type === BOOKING_TYPES.EVENT, [state.form.booking_type]);
  const IS_PROMO = useMemo(() => state.form.booking_type === BOOKING_TYPES.PROMO, [state.form.booking_type]);

  const handleError = (error, message) => {
    const filterError = checkForFilterError(error, message);
    toast.error(<RichToast title={filterError.title} message={filterError.message} />);
  };

  const [createShift, { loading: createShiftLoading }] = useMutation(CREATE_SHIFT_GQL, {
    onCompleted,
    onError: (err) => {
      logError(err, 'CREATE_SHIFT_GQL', BookingForm.displayName);
      handleError(err, t('bookingCalendar.error_creating_booking'));
    },
  });
  const [createEvent, { loading: eventCreateLoading }] = useMutation(SHIFT_EVENT_CREATE, {
    onError: (err) => {
      logError(err, 'SHIFT_EVENT_CREATE', BookingForm.displayName);
      handleError(err, t('bookingCalendar.error_creating_event'));
    },
    onCompleted,
    variables: { businessId: id },
  });

  const [createPromo, { loading: promoCreateLoading }] = useMutation(SHIFT_PROMO_CREATE, {
    onError: (err) => {
      logError(err, 'SHIFT_PROMO_CREATE', BookingForm.displayName);
      handleError(err, t('bookingCalendar.error_creating_promo'));
    },
    onCompleted,
    variables: { businessId: id },
  });

  const [updateEvent, { loading: updateEventLoading }] = useMutation(SHIFT_EVENT_UPDATE, {
    notifyOnNetworkStatusChange: true,
    onCompleted,
    onError: (err) => {
      logError(err, 'SHIFT_EVENT_UPDATE', BookingForm.displayName);
      handleError(err, t('bookingCalendar.error_updating_event'));
    },
    variables: { eventId: bookingId },
  });

  const [updatePromo, { loading: updatePromoLoading }] = useMutation(SHIFT_PROMO_UPDATE, {
    notifyOnNetworkStatusChange: true,
    onCompleted,
    onError: (err) => {
      logError(err, 'SHIFT_PROMO_UPDATE', BookingForm.displayName);
      handleError(err, t('bookingCalendar.error_updating_promo'));
    },
    variables: { eventId: bookingId },
  });

  const [updateShift, { loading: updateShiftLoading }] = useMutation(UPDATE_SHIFT, {
    onError: (err) => {
      logError(err, 'UPDATE_SHIFT', BookingForm.displayName);
      handleError(err, t('bookingCalendar.error_updating_booking'));
    },
    onCompleted,
    variables: {
      shiftId: bookingId,
      recurring: ['series', 'all'].includes(state.form.selector),
      separateSeries: state.form.selector === 'series',
    },
  });

  useEffect(() => {
    setLoading(createShiftLoading || eventCreateLoading || updateEventLoading || updateShiftLoading);
  }, [createShiftLoading, eventCreateLoading, updateEventLoading, updateShiftLoading, setLoading]);


  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const ERRORS = BookingServices.checkBookingForErrors(state.form, dispatch, GROUP_ID_LIST);
      if (!Object.values(ERRORS).every((e) => e === '')) {
        return;
      }
      await checkText([state.form.title, state.form.description].join(' '));

      let imageInput = null; // Make no changes regarding the image
      if (state.form.preview && state.form.image_attachment) {
        // Update image
        imageInput = {
          data: state.form.preview.split(',')[1],
          fileName: state.form.image_attachment.name,
          mimeType: state.form.image_attachment.type.split('/')[1].toUpperCase(),
        };
      } else if (!state.form.preview && !state.form.image_attachment) {
        // Delete image
        imageInput = { data: null };
      }

      const variables = BookingServices.getBookingDataParsed(id, state.form, bookingId, timezone, imageInput);
      //update Form so API will take it, date/start_time/end_time/recurring

      if (bookingId) {
        if (IS_EVENT) {
          await updateEvent({ variables });
        } else if(IS_PROMO) {
          await updatePromo({ variables });
        } else {
          await updateShift({ variables });
        }
      } else {
        if (IS_EVENT) {
          await createEvent({ variables });
        } else if(IS_PROMO) {
          await createPromo({ variables });
        } else {
          await createShift({ variables });
        }
      }
    } catch (error) {
      handleError(error, t('errors.generic'));
    }
  };

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      dispatch(setBookingField(name, value));
      dispatch(setBookingError(name, BookingServices.validateBookingField(name, value)));
    },
    [dispatch]
  );

  const handleCheckboxChange = useCallback(
    ({ target: { name, checked } }) => {
      dispatch(setBookingField(name, checked));
      dispatch(setBookingError(name, BookingServices.validateBookingField(name, checked)));
    },
    [dispatch]
  );

  const handleGroupChange = useCallback(
    ({ name, value }) => {
      dispatch(setBookingField(name, value));
      dispatch(setBookingError(name, BookingServices.validateBookingField(name, value, GROUP_ID_LIST)));
    },
    [dispatch, GROUP_ID_LIST]
  );
  const isLoading = createShiftLoading || eventCreateLoading || updateEventLoading || updateShiftLoading;

  const IS_DISABLED = useMemo(
    () =>
      !state.meta.isEditable || updateShiftLoading || eventCreateLoading || createShiftLoading || updateEventLoading || promoCreateLoading || updatePromoLoading,
    [state.meta.isEditable, updateShiftLoading, eventCreateLoading, createShiftLoading, updateEventLoading, promoCreateLoading, updatePromoLoading]
  );
  const isValidated = !Object.values(state.errors).reduce((prev, curr) => {
    return prev + curr;
  }, '');
  return (
    <form
      onSubmit={onSubmit}
      className={classNames({
        [S.bookingCreateForm]: IS_CREATE,
        [S.bookingUpdateModal]: !IS_CREATE && !IS_EVENT && !IS_PROMO,
        [S.bookingUpdateEventModal]: !IS_CREATE && IS_EVENT,
        [S.bookingUpdatePromoModal]: !IS_CREATE && IS_PROMO,
      })}
      noValidate
    >


      <RowBookingTime isDisabled={IS_DISABLED} handleChange={handleChange} />

      <FormSelectField
        id='modal_booking_type'
        label={t('bookingCalendar.booking_type')}
        gridArea='booking_type'
        name='booking_type'
        value={state.form.booking_type}
        error={t(state.errors.booking_type)}
        isDisabled={
          IS_DISABLED || (bookingId && [BOOKING_TYPES.EVENT, BOOKING_TYPES.PROMO].includes(state.form.booking_type))
        }
        options={
          bookingId && ![BOOKING_TYPES.EVENT, BOOKING_TYPES.PROMO].includes(state.meta.initial.shift_type)
            ? BOOKING_TYPE_OPTIONS.filter(({ value }) => ![BOOKING_TYPES.EVENT, BOOKING_TYPES.PROMO].includes(value))
            : BOOKING_TYPE_OPTIONS
        }
        handleChange={(e) => {
          if (e.target.value === BOOKING_TYPES.EVENT) {
            dispatch(setBookingField('duplicates', []));
          }
          handleChange(e);
        }}
      />

      {IS_CREATE && (
        <>
          <BookingCheckBox
            gridArea='recurring'
            label={t('bookingCalendar.repeats_weekly')}
            name='recurring'
            isChecked={IS_EVENT ? false : state.form.recurring}
            isDisabled={IS_DISABLED || IS_EVENT}
            handleChange={handleCheckboxChange}
          />

          {state.form.date && (
            <BookingDuplicateField isEvent={IS_EVENT} isDisabled={IS_DISABLED || IS_EVENT} timezone={timezone} />
          )}
        </>
      )}

      {IS_EVENT && <RowBookingEvent isDisabled={IS_DISABLED} handleChange={handleChange} />}
      {IS_PROMO && <RowBookingPromo isDisabled={IS_DISABLED} handleChange={handleChange} />}

      <FormNumberField
        id='modal_booking_slots'
        value={parseInt(state.form.slots)}
        error={t(state.errors.slots)}
        handleChange={handleChange}
        isDisabled={IS_DISABLED}
        gridArea='slots'
        name='slots'
        label={t('bookingCalendar.num_of_dancers')}
        min={1}
      />

      <BookingCheckBox
        gridArea='hardCap'
        label={t('common.strict_limit')}
        name='hardCap'
        isChecked={state.form.hardCap}
        isDisabled={IS_DISABLED}
        handleChange={handleCheckboxChange}
      >
        <>
          <FaInfoCircle />
          <h6>{t('bookingCalendar.strict_limit_info')}</h6>
        </>
      </BookingCheckBox>

      <BookingGroupsSelect
        label={t('bookingCalendar.preapproved_groups')}
        gridArea='preapproved'
        name='preapprovedIds'
        isDisabled={IS_DISABLED}
        collection={GROUP_OPTIONS}
        value={state.form.preapprovedIds.map((id) => id.toString())}
        handleChange={handleGroupChange}
      />

      {IS_CREATE && (
        <BookingGroupsSelect
          label={t('bookingCalendar.notify_groups_of_booking')}
          gridArea='notify'
          name='notifyIds'
          isDisabled={IS_DISABLED}
          collection={GROUP_OPTIONS}
          value={state.form.notifyIds.map((id) => id.toString())}
          handleChange={handleGroupChange}
        />
      )}

      {!IS_CREATE && !IS_EVENT && state.meta.isEditable && state.meta.initial.recurring && (
        <BookingSelectorField isDisabled={IS_DISABLED} handleChange={handleChange} />
      )}
      {!IS_DISABLED && <RowBookingButtons bookingId={bookingId} onClose={!isLoading && onClose} disableSubmit={!isValidated} />}
    </form>
  );
};

BookingForm.displayName = 'BookingForm';
BookingForm.propTypes = {
  bookingId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

BookingForm.defaultProps = {
  bookingId: null,
};

export { BookingForm };