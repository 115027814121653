import { gql } from 'apollo-boost';

export const CREATE_FEED = gql`
  mutation FeedCreate($input: FeedScheduleCreateInput!) {
    feed_schedule_create(input: $input) {
      feed_schedule {
        id
      }
    }
  }
`;

export const UPDATE_FEED = gql`
  mutation FeedUpdate($input: FeedScheduleUpdateInput!) {
    feed_schedule_update(input: $input) {
      feed_schedule {
        id
      }
    }
  }
`;

export const CREATE_FEED_PUBLISHER = gql`
  mutation FeedPublisherCreate($input: FeedPublisherCreateInput!) {
    feed_publisher_create(input: $input) {
      feed_publisher {
        id
      }
    }
  }
`;

export const UPDATE_FEED_PUBLISHER = gql`
  mutation FeedPublisherUpdate($input: FeedPublisherUpdateInput!) {
    feed_publisher_update(input: $input) {
      feed_publisher {
        id
      }
    }
  }
`;
