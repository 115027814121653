import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import S from './dancer-search-selected-row.module.scss';
import { DancerSortButtons } from './search-filter/dancer-sort-buttons';
import * as DancerSearchCtx from '../dancer-search-context';
import { useModal } from '../../../../global-hooks';
import { DropDownWithSelected } from '../../../../common/drop-down-with-selected';
import { GET_DANCER_SEARCH_SQL } from '../../../../../graphql/queries/dancer-search-query';
import { UI_MODALS } from '../../../../../helpers/enums';

const DancerSortAndSelectedRow = () => {
  const { t } = useTranslation();
  const { initModal } = useModal();
  const {
    dispatch,
    searchParams,
    state: { selected },
  } = DancerSearchCtx.useDancerSearchCtx();
  const TOTAL_QUERY = useQuery(GET_DANCER_SEARCH_SQL, { variables: searchParams });
  const TOTAL = TOTAL_QUERY?.data?.dancers?.totalCount ?? 0;

  const handleInviteClick = useCallback(
    () => initModal(UI_MODALS.INVITE_DANCER, { dancers: selected }),
    [initModal, selected]
  );

  const handleGroupClick = useCallback(
    () => initModal(UI_MODALS.ADD_TO_GROUP, { dancerIds: selected.map(({ id }) => id) }),
    [initModal, selected]
  );

  const handleDeselect = useCallback(() => dispatch(DancerSearchCtx.setDancerSearchField('selected', [])), [dispatch]);

  return (
    <div className={S.subHeaderWrapper}>
      <div className={S.selectedArea}>
        {TOTAL > 0 && (
          <DropDownWithSelected selectedCount={selected.length} totalCount={TOTAL} onDeselect={handleDeselect} />
        )}

        {selected.length > 0 && (
          <div className={S.dancerActionArea}>
            <button className={S.invertedBtnBlue} onClick={handleGroupClick}>
              {t('dancerPage.add_to_group')}
            </button>
            <button className={S.invertedBtnBlue} onClick={handleInviteClick}>
              {t('common.invite')}
            </button>
          </div>
        )}
      </div>

      <DancerSortButtons />
    </div>
  );
};

DancerSortAndSelectedRow.displayName = 'DancerSortAndSelectedRow';

export { DancerSortAndSelectedRow };
