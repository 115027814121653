import { useMutation } from '@apollo/react-hooks';
import * as PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './manager-table.module.scss';
import { Image } from '../../../common/images/img-file';
import { RouteCreator } from '../../../routes/route-constants';
import { REMOVE_EMPLOYEE_FROM_CLUB } from '../../../../graphql/mutations/employee-delete';
import { BUSINESS_EMPLOYEES_GQL } from '../../../../graphql/queries/employee-list-query';
import { logError } from '../../../../helpers/errors/bug-report';

const ManagerTable = ({ search, list, businessId, isAsc, canEdit }) => {
  const { t } = useTranslation();

  const [removeEmployee] = useMutation(REMOVE_EMPLOYEE_FROM_CLUB, {
    onError: (err) => {
      logError(err, 'REMOVE_EMPLOYEE_FROM_CLUB', ManagerTable.displayName);
      toast.error(`${t('errors.server.500')}: ${t('employeePage.employee_remove_failed')}`);
    },
    onCompleted: () => toast.success(t('employeePage.employee_remove_success')),
  });

  const EMPLOYEE_LIST = useMemo(
    () =>
      list
        .filter(({ employee }) => employee.name.toUpperCase().includes(search.toUpperCase()))
        .sort((a, b) => {
          const NAME_A = a.employee.name.toUpperCase();
          const NAME_B = b.employee.name.toUpperCase();
          let comparison = 0;

          if (isAsc) {
            if (NAME_A > NAME_B) {
              comparison = 1;
            } else if (NAME_A < NAME_B) {
              comparison = -1;
            }
          } else {
            if (NAME_A < NAME_B) {
              comparison = 1;
            } else if (NAME_A > NAME_B) {
              comparison = -1;
            }
          }
          return comparison;
        }),
    [list, isAsc, search]
  );

  const onDelete = useCallback(
    async (id, name) => {
      if (window.confirm(t('employeePage.employee_remove_confirm', { name }))) {
        await removeEmployee({
          variables: { employmentId: id },
          update: (cache) => {
            const QUERY = { query: BUSINESS_EMPLOYEES_GQL, variables: { businessId } };
            const { business } = cache.readQuery(QUERY);
            cache.writeQuery({
              ...QUERY,
              data: {
                business: {
                  ...business,
                  employments: business.employments.filter((e) => e.id !== id),
                },
              },
            });
          },
        });
      }
    },
    [removeEmployee, businessId]
  );

  return (
    <>
      <div className={S.managerHeader}>
        <div>{t('common.employee')}</div>
        <div>{t('common.actions')}</div>
      </div>
      <ul className={S.mangerList}>
        {EMPLOYEE_LIST.map(({ employee, id, added_by }) => (
          <li key={`EMPLOYEE_${employee.id}`} className={S.listItem}>
            <Image src={employee?.first_image?.thumb} alt={employee.name} fallback='USER' />

            <div className={S.colInfo}>
              <div className={S.employeeName}>
                {employee.name} - <span>{t(`employeePage.roles.${employee.roles[businessId]?.slug}`)}</span>
              </div>
              <a className={S.employeeLink} href={`mailto:${employee.email}`}>
                {employee.email}
              </a>
              <a className={S.employeeLink} href={`tel:${employee.phone_number}`}>
                {employee.phone_number}
              </a>
            </div>

            <div className={S.colInfo}>
              <div>
                {t('common.added_by')}: <span>{added_by ? added_by.name : t('common.unavailable')}</span>
              </div>
              <div>{employee.notes}</div>
            </div>

            {canEdit && (
              <div className={S.colAction}>
                <Link className={S.editBtn} to={RouteCreator.employeeEdit(id)}>
                  {t('common.edit')}
                </Link>
                <button className={S.deleteBtn} type='button' onClick={() => onDelete(id, employee.name)}>
                  {t('common.remove')}
                </button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

ManagerTable.displayName = 'ManagerTable';
ManagerTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAsc: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export { ManagerTable };
